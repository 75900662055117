$bgcolor: #eee;
$textcolor: #444;
$textcolorfaint: #888;
$separatorcolor: #ccc;
$newcolor: #fc0;

$textwidth: 40rem;

$marginfull: 1.5rem;
$marginhalf: 0.75rem;

$header-height: 60px;
$header-width: 54px;

@font-face {
  font-family: Outfit;
  src: url('/assets/fonts/Outfit-VariableFont_wght.ttf');
}

body {
  min-height: 100vh;
  display: grid;
  grid-template-rows: 1fr auto;
  background-attachment: fixed;
  background-color: $bgcolor;
  background-image: url('/assets/images/bg-pattern.png');
  background-size: 366px auto;
  color: $textcolor;
  font-family: 'Outfit', sans-serif;
  font-weight: 350;
}

header {
  position: fixed;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  padding: 12px;
  z-index: 3;
  background-attachment: fixed;
  background-color: $bgcolor;
  background-image: url('/assets/images/bg-pattern.png');
  background-size: 366px auto;
}

@media (max-width: 720px) {

  header {
    flex-direction: row;
    width: 100%;
    height: $header-height;
  }

}

@media (min-width: 721px) {

  header {
    flex-direction: column;
    height: 100%;
    width: $header-width;
  }

}

.title {
  flex: 0;
}

.menu {
  flex: 1;

  ul {
    display: flex;
    justify-content: end;
    list-style-type: none;
    margin: 0;
  }

  a {
    display: block;
    padding: 6px;
  }
}

@media (max-width: 720px) {

  .menu ul {
    margin-right: -6px;
  }

}

@media (min-width: 721px) {

  .menu {
    display: flex;
  }

  .menu ul {
    flex-direction: column;
    margin-bottom: -6px;
  }

  .menu li {
    display: block;
  }

  .menu img {
    display: block;
  }

}

.commissions {
  flex: 0;
}

.title img {
  display: block;
  height: 36px;
}

.menu img {
  height: 24px;
}

.commissions img {
  height: 24px;
}

.eighteen {
  display: inline-block;
  margin: -3px;
  padding: 2px 2px 0;
  transform: translateY(-10px);
  color: #c32;
  line-height: 1;
  font-size: 0.75em;
  font-weight: 400;
}

footer {
  display: flex;
  justify-content: space-between;
}

footer p {
  margin: 0;
  font-size: 0.75em;
  font-weight: 400;
}

@media (max-width: 720px) {
  
  main {
    padding-top: $header-height;
  }

  footer {
    padding: 12px;
  }
}

@media (min-width: 721px) {

  main {
    padding-left: $header-width;
  }

  footer {
    padding: 12px 12px 12px $header-width;
  }

}

hgroup {
  margin: 1em 0;

  h1 {
    margin: 0;
    line-height: 1.25;
  }
  p {
    margin: 0;
    color: $textcolorfaint;
  }
}

h1 {
  font-weight: 250;
  font-size: 1.5em;
}

h2 {
  font-weight: 300;
  font-size: 1.2em;
}

ul {
  padding-left: 0;
}

a {
  color: $textcolor;
}

hr {
  border-color: $textcolor transparent transparent;
}

.text {
  max-width: $textwidth;
  margin: 0 auto;
  line-height: 1.5;
}

.gallery article {
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  box-shadow: 0 0 0 black;
}

.gallery article:hover {
  transform: scale(1.125);
  box-shadow: 0 0 6px rgb(0 0 0 / 0.5);
  z-index: 2;
}

.gallery article.new::after {
  content: "new";
  position: absolute;
  top: 6px;
  right: 6px;
  color: $newcolor;
  font-size: 0.75em;
  font-weight: 700;
  text-transform: uppercase;
  text-shadow:
    1px 1px 0 black,
    1px -1px 0 black,
    -1px -1px 0 black,
    -1px 1px 0 black;
  animation-name: wiggle;
  animation-duration: 0.5s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-timing-function: ease-in-out;
}

@keyframes wiggle {
  from {
    transform: rotate(10deg);
  }
  to {
    transform: rotate(-10deg);
  }
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.no-js {

  .gallery {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0 (-$marginhalf) (-$marginhalf) 0;
    opacity: 0;
    animation-name: fadein;
    animation-duration: 0.5s;
    animation-delay: 1s;
    animation-fill-mode: forwards;
    animation-iteration-count: 1;
  }

  .gallery article {
    display: block;
    margin: 0 $marginhalf $marginhalf 0;
  }

  .gallery article img {
    display: block;
    height: 10rem;
  }

  @media (max-width: 480px) {
    .gallery {
      display: block;
    }

    .gallery article img {
      height: auto;
      width: 100%;
    }
  }

}

.js {
  
  &.not-loaded {

    .gallery {
      opacity: 0;
    }

    footer {
      display: none;
    }
  }

  &.loaded {
    .gallery {
      transition: opacity 0.5s linear;
    }
  }

  .gallery {
    display: block;
    position: relative;

  }

  .gallery article {
    position: absolute;
    width: 12.5%;
  }


  .gallery article img {
    width: 100%;
    display: block;
  }

  @for $i from 1 through 8 {
    .in-column-#{$i} {
      left: #{calc(($i - 1) / 8) * 100%} ;
    }
  }

  $max-column-width: 160px;
  @for $j from 8 through 1 {

    @media (max-width: $j * $max-column-width) {

      .gallery article {
        width: calc(100% / $j);
      }

      @for $i from 1 through $j {
        .in-column-#{$i} {
          left: #{calc(($i - 1) / $j) * 100%} ;
        }
      }

    }

  }

  @media (max-width: 160px) {
    .gallery article {
      position: static;
    }
  }

}

.piece img {
  display: block;
  margin: 0 auto 1em;
  max-width: 100%;
}

@media (max-width: 720px) {
  .piece img {
    max-height: calc(100vh - #{$header-height});
  }
}

@media (min-width: 721px) {
  .piece img {
    padding-top: 1em;
    max-height: calc(100vh - 2em);
  }
}

.piece audio {
  display: block;
  margin: 1em auto;
}

.text {
  padding: 0 12px;

  ul {
    padding-left: 1em;
  }

  .tags {
    padding-left: 0;

    li {
      display: inline;
    }
  }
}

.current-tag {
  position: fixed;
  z-index: 4;
  font-size: 1em;
  font-weight: 350;
  line-height: 1;

  a {
    position: fixed;
    border-radius: $marginfull;
    padding: $marginhalf $marginfull+$marginhalf $marginhalf $marginhalf;
    margin: 0;
    background-color: $bgcolor;
    box-shadow: 0 0 6px rgb(0 0 0 / 0.5);
    color: $textcolor;
    text-decoration: none;

    &::after {
      content: '×';
      position: absolute;
      top: 0.75rem;
      right: 0.75rem;
      font-size: 2em;
      line-height: 0.375;
    }
  }
}

@media (max-width: 720px) {
  .current-tag a {
    left: 50%;
    transform: translate(-50%);
    bottom: $marginfull+$marginhalf;
  }

}

@media (min-width: 721px) {
  .current-tag a {
    right: $marginfull;
    top: $marginfull;
  }
}

.piece-nav {
  max-width: $textwidth;
  margin: 0 auto;
  padding: 0 12px;

  ul {
    display: flex;
    justify-content: space-between;
    border-top: 1px solid $separatorcolor;
    padding-top: 12px;
    color: $textcolorfaint;
    list-style-type: none;
    line-height: 1.5;
  }

  li {
    flex: 1;
  }

  img {
    height: 4.5rem;
  }

  h2 {
    font-size: inherit;
    margin: inherit;
    font-weight: inherit;
  }

  li:first-child {
    text-align: left;

    img {
      float: left;
      margin-right: 0.5rem;
    }
  }

  li:last-child {
    text-align: right;

    img {
      float: right;
      margin-left: 0.5rem;
    }
  }

  @media (max-width: 720px) {
    img {
      float: none !important;
    }
  }
}